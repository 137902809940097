import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ApiUrl from "../Api/Api";
import Header from "../components/header";
import Footer from "../components/footer";

const Fcrareport = () => {
  const [newsletterData, setNewsletterData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${ApiUrl}/get/Newsletter`)
      .then((response) => {
        const allData = response.data.data;
        allData.sort((a, b) => new Date(b.eventdate) - new Date(a.eventdate));
        const latestData = allData.slice(0, 10);
        setNewsletterData(latestData.reverse());
        setLoading(false); // Set loading to false when data is received
      })
      .catch((error) => {
        console.error("Error fetching newsletter data:", error);
        setLoading(false); // Set loading to false in case of error too
      });
  }, []);

  const getFileExtension = (fileId) => {
    return fileId.split(".").pop().toLowerCase();
  };

  const renderFileIcon = (fileId) => {
    const extension = getFileExtension(fileId);
    if (extension === "pdf") {
      return (
        <img
          src="/images/pdf.png"
          alt="PDF Icon"
          className="mb-3"
          style={{ width: "50px" }}
        />
      );
    } else if (extension === "doc" || extension === "docx") {
      return (
        <img
          src="/images/docx.webp"
          alt="DOC Icon"
          className="mb-3"
          style={{ width: "50px" }}
        />
      );
    } else if (extension === "xls" || extension === "xlsx") {
      return (
        <img
          src="/images/excel.png"
          alt="Excel Icon"
          className="mb-3"
          style={{ width: "50px" }}
        />
      );
    } else if (extension === "jpg" || extension === "png" || extension === "gif" || extension === "jpeg") {
      return (
        <img
          src="/images/image.png"
          alt="Icon"
          className="mb-3"
          style={{ width: "50px" }}
        />
      );
    }
    else {
      return null;
    }
  };


  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container">
          <h3 className="entry-title motherhouse">
            <a href="/fcrareport">FCRA Reporting</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp; </Link>
            <span className="pagename">FCRA Reporting</span>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-12">
            <div className="mt-5" id="newsletterprog">
              {loading ? (
                <p><b>Loading...</b></p>
              ) : newsletterData.length > 0 ? (
                newsletterData.map((newsletter) => (
                  <div
                    key={newsletter.id}
                    className="card shadow mb-2"
                    style={{ height: "100px" }}
                  >
                    <div className="card-body text-center">
                      <div className="d-flex justify-content-between align-items-center">
                        {renderFileIcon(newsletter.file_id)}
                        <div className="text-center">
                          <h5 className="card-title mb-2"><a href={newsletter.file_url}>{newsletter.title}</a></h5>
                          <p className="card-text mb-3">{newsletter.eventdate}</p>
                        </div>
                        <a
                          href={newsletter.file_url}
                          className="btn btn-sm btn-primary"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>
                  <b>No FCRA Reporting Data available</b>
                </p>
              )}

              {newsletterData.length >= 10 && (
                <center>
                  <Link
                    to={"/newsletter"}
                    className="btn btn-sm btn-primary mt-3"
                  >
                    View More
                  </Link>
                </center>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default Fcrareport;
