import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import Footer from "../components/footer";
import Header from "../components/header";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { MssccUrl } from "../components/API/Api";

function CalendarComponent() {
  let IsCalendar = window.location.href;
  IsCalendar = IsCalendar.split("#");

  const calendarRef = useRef(null);
  const [eventCalendarData, setEventCalendarData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const size = IsCalendar[1] === "Iscal" ? "md" : "md";
  const modaltitle =
    IsCalendar[1] === "Iscal" ? "Ordination  Details" : "Birthday Details";

  const handleEventClick = (eventId) => {
    console.log(eventId);
    const mergedData = [...eventCalendarData];
    const foundEvent = mergedData?.find(
      (event) => event?.id === parseInt(eventId)
    );
    console.log(foundEvent);
    setSelectedEvent(foundEvent);
    setShowModal(true);
  };

  useEffect(() => {
    async function fetchData() {
      if (calendarRef.current) {
        if (IsCalendar[1] !== "Iscal" && IsCalendar[1] !== "Isevent") {
          try {
            const response = await axios.get(
              `${MssccUrl}/member/province/birthday/this_month/1`
            );
            const data = response.data;
            if (Array.isArray(data?.data)) {
              const events = data?.data?.map((item) => {
                const parts = item.dob.split("-");
                const day = parts[0];
                const monthName = parts[1];
                function getMonthNumber(monthName) {
                  const date = new Date(`${monthName} 1, 2000`);
                  const monthNumber = date.getMonth() + 1;
                  return monthNumber.toString().padStart(2, "0");
                }
                const monthN0 = getMonthNumber(monthName);
                const year = new Date().getFullYear();
                const date = `${year}-${monthN0}-${day.toString()}`;

                return {
                  id: item.id,
                  title: item.member_name,
                  date: date.trim(),
                };
              });
              console.log(events);
              setEventCalendarData(events);
            }
          } catch (error) {
            console.error("Error fetching events from API", error);
          }
        }
        if (IsCalendar[1] === "Iscal") {
          try {
            const response = await axios.get(
              `${MssccUrl}/member/province/ordination/1`
            );
            const data = response.data.data;

            if (Array.isArray(data)) {
              const events = data.map((item) => {
                const parts = item.ordination_date.split("-");
                if (parts) {
                  const currentDate = new Date();
                  const fullyear = currentDate.getFullYear();
                  const month = parts[1].toString();
                  const day = parts[0].toString();
                  const isoDate = `${fullyear}-${month}-${day}`;

                  return {
                    id: item.id,
                    title: item.member_name,
                    date: isoDate,
                    color: "red",
                    description: item.community_id,
                  };
                } else {
                  console.error("Invalid date format:");
                  return null;
                }
              });

              setEventCalendarData(events);
            }
          } catch (error) {
            console.error("Error fetching news events from API", error);
          }
        }
      }
    }

    fetchData();
  }, [IsCalendar]);

  function confitrm(data) {
    const date = data.split("-");
    const rightdate = date[2] + "-" + date[1] + "-" + date[0];
    console.log(rightdate);
    return rightdate;
  }

  return (
    <>
      <Header />
      <div className="container">
        <h1 className="entry-title">
          <center></center>
        </h1>
        <div id="calendar">
          <FullCalendar
            events={[...eventCalendarData]}
            ref={calendarRef}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
            ]}
            initialView="dayGridMonth"
            headerToolbar={{
              start: "prev,next today",
              center: "title",
              end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            navLinks={false}
            dayMaxEventRows={2}
            editable={true}
            eventResizable={true}
            eventClick={(arg) => {
              const eventId = arg?.event?.id;
              handleEventClick(eventId);
            }}
          />

          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size={size}>
            <Modal.Header closeButton>
              <Modal.Title>{modaltitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="calendarbody">
                {selectedEvent?.title && (
                  <p>
                    <strong>Title :</strong> {selectedEvent?.title}
                  </p>
                )}
                {selectedEvent?.date && (
                  <p>
                    <strong>Date :</strong> {confitrm(selectedEvent?.date)}
                  </p>
                )}
                {selectedEvent?.description && (
                  <>
                    <strong>Description :</strong>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedEvent?.description,
                      }}
                    />
                  </>
                )}
                {selectedEvent?.description_html && (
                  <>
                    <strong>Description :</strong>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedEvent?.description_html,
                      }}
                    />
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
}

export default CalendarComponent;
