import React, { useState, useEffect } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import { Link } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../components/API/Api";

function AboutUs() {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    axios
      .get(`${ApiUrl}/get/Pages`)
      .then((response) => {
        setAboutData(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching about data:", error);
      });
  }, []);
  const filteredData = aboutData
    ? aboutData.filter((item) => item.id === 2)
    : null;

  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container">
          <h3 className="entry-title motherhouse">
            <a href="/aboutus">About Us</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp; </Link>
            <span className="pagename">About Us</span>
          </div>
        </div>
      </div>

      <div className="container subpage">
        <div className="row">
          <div className="col-lg-12">
            {filteredData &&
              filteredData?.map((item) => (
                <div key={item.id} className="mt-5">
                  <p dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              ))}
            {!filteredData && <p>Loading...</p>}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default AboutUs;
