import React from "react";

const Details = () => {
  return (
    <>
      <div className="home-page-events">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="section-heading">
                <h2 className="entry-titlenew">
                  REV. FR. ANGELO TERRACCIANO MSSCC
                </h2>
              </div>
              <div className="row">
                <div className="col-4">
                  <img
                    src="images/general.png"
                    alt=""
                    style={{
                      display: "block",
                      marginLeft: "0",
                      width: "100%",
                      marginRight: "auto",
                    }}
                  />
                </div>
                <div className="col-8">
                  <p
                    style={{
                      textAlign: "justify ",
                      fontSize: "14px",
                    }}>
                    Atthe General Chapter 2019 held at Rome in March 2019, Fr.
                    Angelo Terracciano was elected as the new Superior General
                    of the congregation. The election took place during the
                    congregation’s 18th Ordinary General Chapter in Rome. The
                    General Chapter began on March 4 and concluded on March 24..
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <b className="entry-titlenew">Superior General</b>
                <ul>
                  <li>
                    <b>The present General Team 2019-2025</b>
                  </li>
                  <li>
                    <b>Superior General : </b>Fr. Angelo Terracciano
                  </li>
                  <li>
                    <b>Vicar General : </b>Fr. Peter Morka
                  </li>
                  <li>
                    <b>Second Counselor : </b>Fr. Sebastian Dias
                  </li>
                  <li>
                    <b>Economer General :</b> Fr. Sebastian Dias
                  </li>
                  <li>
                    <b>Third Counselor : </b>Fr. George Sen
                  </li>
                  <li>
                    <b>Fourth Counselor :</b>Fr. Cornelius Okafor
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="section-heading">
                <h2 className="entry-titlenew">REV.FR. BENNY JAMES MSSCC</h2>
              </div>
              <div className="row">
                <div className="col-4">
                  <img
                    src="images/regional_superior.png"
                    alt=""
                    style={{
                      display: "block",
                      marginLeft: "0",
                      width: "100%",
                      marginRight: "auto",
                    }}
                  />
                </div>
                <div className="col-8">
                  <p
                    style={{
                      textAlign: "justify ",
                      fontSize: "14px",
                    }}>
                    Atthe General Chapter 2019 held at Rome in March 2019, Fr.
                    Angelo Terracciano was elected as the new Superior General
                    of the congregation. The election took place during the
                    congregation’s 18th Ordinary General Chapter in Rome. The
                    General Chapter began on March 4 and concluded on March 24..
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <b className="entry-titlenew">Regional Superior</b>
                <ul>
                  <li>
                    <b>The Regional Team</b>
                  </li>
                  <li>
                    <b>Regional Superior : </b> Fr. Benny James
                  </li>
                  <li>
                    <b>Vicar : </b>Fr. Ephraim Dias
                  </li>
                  <li>
                    <b>Counselor : </b>Fr. Cyriac Mathew
                  </li>
                  <li>
                    <b>Economer :</b> Fr. Joby Joseph
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
