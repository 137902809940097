import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="site-footer">
        <div className="footer-widgets">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="section-heading">
                  <h2 className="entry-title" id="footnewtitle">
                    About Us
                  </h2>
                </div>
                <div className="row">
                  <div className="col-4">
                    <img
                      src="images/footlogo.jpg"
                      alt=""
                      style={{
                        display: "block",
                        marginLeft: "0",
                        maxWidth: "83px",
                        marginRight: "auto",
                      }}
                    />
                  </div>
                  <div className="col-8">
                    <p
                      style={{
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      We do Pastoral Activities, Preaching and Education with
                      special attention to the poor
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="foot-contact">
                  <div className="section-heading">
                    <h2
                      className="entry-title"
                      id="footnewtitle"
                      style={{ marginLeft: "34px" }}
                    >
                      Contact Us
                    </h2>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-map-marker mr-2" />
                      <p style={{ color: "#fff" }}>
                        Regional Superior
                        <br /> Missionaries of the Sacred Hearts,
                        <br /> 9, Alfred Street, Richmond Town, <br />
                        Bangalore – 560025 Karnataka, India.
                      </p>
                    </li>
                    <li>
                      <i className="fa fa-phone mr-2" />
                      <Link to={"tel:+919591049400"}>+91 - 9591049400</Link>
                    </li>
                    <li>
                      &nbsp;
                      <i className="fa fa-envelope mr-2" />
                      <Link to={"mailto:sacredheartsfatherssociety@gmail.com"}>
                        &nbsp;&nbsp;sacredheartsfatherssociety@gmail.com
                      </Link>
                    </li>
                    <li>
                      <span>
                        <li>
                          <a href="/" target="_blank" rel="noreferrer">
                            <i className="fab fa-twitter mr-2" />
                          </a>
                          &nbsp;
                          <a href="/" target="_blank" rel="noreferrer">
                            <i className="fab fa-google-plus mr-2" />
                          </a>
                          &nbsp;
                          <a href="/" target="_blank" rel="noreferrer">
                            <i className="fab fa-facebook mr-2" />
                          </a>
                          &nbsp;
                          <a href="/" target="_blank" rel="noreferrer">
                            <i className="fab fa-linkedin" />
                          </a>
                        </li>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="foot-latest-news">
                  <div className="section-heading">
                    <h2 className="entry-title" id="footnewtitle">
                      Our Location
                    </h2>
                  </div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3887.1217188889273!2d77.700584!3d13.02792!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1057ed853839%3A0xb5bd3f81ddf7014c!2sMissionaries%20of%20the%20Sacred%20Hearts%20(%20M%20Ss%20Cc)!5e0!3m2!1sen!2sin!4v1707976054498!5m2!1sen!2sin"
                    width="250"
                    height="140"
                    allowFullScreen=""
                    title="msscc"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="section-heading">
                  <h2 className="entry-title" id="footnewtitle">
                    Activities
                  </h2>
                </div>
                <div className="row">
                  <div className="col-4">
                    <img
                      src="images/footnew.jpg"
                      alt=""
                      style={{
                        display: "block",
                        marginLeft: "0",
                        maxWidth: "83px",
                        marginRight: "auto",
                      }}
                    />
                  </div>
                  <div className="col-8">
                    <p
                      style={{
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      On July 14, 2015 by mandate of Pope Francis, the Apostolic
                      Penitentiary granted the
                    </p>
                  </div>
                  <p
                    style={{
                      color: "white",
                      fontSize: "14px",
                    }}
                  >
                    celebration of a Jubilee Year from September 23, 2015 to
                    September 23, 2016 in celebration of the Bicentennial of the
                    priestly ordination of the Founder of the Missionaries of
                    the Sacred Hearts of Jesus and Mary, St. Gaetano Errico.
                  </p>
                </div>
              </div>
            </div>
            <div style={{ marginBottom: "-17px" }}>
              <p
                className="text-center"
                style={{ fontSize: "14px", color: "white" }}
              >
                Copyright © {currentYear} Missionaries of the Sacred Hearts of
                Jesus & Mary, All rights reserved. Powered by
                <a
                  className="tech"
                  style={{ color: "#f6bb79" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.boscosofttech.com/"
                >
                  &nbsp;Boscosofttechnologies
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
