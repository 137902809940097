import React, { useEffect, useRef, useState } from "react";
import Swiper from "swiper";
import axios from "axios";
import ApiUrl from "../Api/Api";

function Slider() {
  const sliderId = "mySlider";
  const slideshowRef = useRef(null);
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    axios
      .get(`${ApiUrl}/get/slider`)
      .then((response) => {
        setApiData(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data from the API: ", error);
      });

    const swiper = new Swiper(`#${sliderId}`, {
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      slidesPerView: 1,
      spaceBetween: 20,
      on: {
        slideChange: () => {},
      },
    });

    const interval = setInterval(() => {
      swiper.slideNext();
    }, 5000);

    return () => {
      clearInterval(interval);
      swiper.destroy();
    };
  }, [sliderId]);

  return (
    <div
      id={sliderId}
      className="swiper-container hero-slider"
      ref={slideshowRef}>
      <div className="swiper-wrapper">
        {apiData?.map((data) => (
          <div className="swiper-slide hero-content-wrap" key={data.id}>
            <div className="image-overlay-container">
              <img src={data.image} alt={data.title} className="slide-image" />
              {/* <div className="image-overlay">
                <div className="welcome-content">
                  <header className="entry-header">
                    <h2 className="entry-title">{data.title}</h2>
                  </header>
                  <div
                    className="entry-content mt-2 text-white"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {data.content}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        ))}
      </div>
      <div
        className="swiper-button-prev custom-button"
        onClick={() => slideshowRef.current?.swiper.slidePrev()}></div>
      <div
        className="swiper-button-next custom-button"
        onClick={() => slideshowRef.current?.swiper.slideNext()}></div>
    </div>
  );
}

export default Slider;
