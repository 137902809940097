import React, { useState, useEffect } from 'react';
import Footer from "../components/footer";
import Header from "../components/header";
import { Link } from "react-router-dom";
import axios from 'axios';
import { ApiUrl } from '../components/API/Api';

function History() {
  const [historyData, setHistoryData] = useState(null);

  useEffect(() => {
    axios.get(`${ApiUrl}/get/Pages`)
      .then(response => {
        setHistoryData(response?.data?.data);
      })
      .catch(error => {
        console.error('Error fetching history data:', error);
      });
  }, []);

  // Filter historyData to contain only the item with id = 1
  const filteredData = historyData ? historyData.filter(item => item.id === 1) : null;

  return (
    <>
      <Header />
      <div className="home-mother">
        <div className="container">
          <h3 className="entry-title motherhouse">
            <a href="/history">History</a>
          </h3>
          <div className="brudcrums">
            <Link to={"/"}>Home &nbsp;»&nbsp;{" "}</Link>
            <span className="pagename">History</span>
          </div>
        </div>
      </div>

      <div className="container subpage">
        <div className="row">
          <div className="col-lg-12">
            {filteredData && filteredData.map(item => (
              <div key={item.id} className='mt-5'>
                <p dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            ))}
            {!filteredData && <p>Loading...</p>}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default History;
