import React, { useState, useEffect } from "react";
import axios from "axios";
import { MssccUrl } from "./API/Api";

function Scrollbar() {
  const [newsData, setNewsData] = useState([]);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const currentDate = new Date();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${MssccUrl}/news/province/1`);
        const allNewsData = response?.data?.data;
        setNewsData(allNewsData);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let interval;

    if (newsData.length > 0) {
      interval = setInterval(() => {
        setCurrentNewsIndex((prevIndex) =>
          prevIndex === newsData.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [newsData]);

  const filteredNewsData = newsData.filter(
    (item) => new Date(item.date) >= currentDate
  );

  return (
    <>
      <div className="scrollbar">
        <div className="container">
          <div className="row flex-wrap justify-content-center justify-content-lg-between align-items-lg-center">
            <div className="col-4 col-lg-2 d-flex">
              <div className="label ripple">Flash News</div>
            </div>
            <div className="col-8 col-lg-10 d-md-flex flex-wrap justify-content-center justify-content-lg-start mb-3 mb-lg-0">
              <div className="marqueenews">
                <div className="marquee">
                  <p>
                    {filteredNewsData.length > 0 ? (
                      filteredNewsData.map((newsItem, index) => (
                        <span
                          key={index}
                          style={{
                            display:
                              index === currentNewsIndex ? "inline" : "none",
                          }}>
                          <img
                            src="images/logos/output-onlinegiftools.gif"
                            style={{
                              maxWidth: "40px",
                            }}
                            alt=""
                          />
                          {newsItem.name} - {newsItem.date}
                        </span>
                      ))
                    ) : (
                      <span>No flash news available</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Scrollbar;
