import React from "react";

function Ourstatic() {
  return (
    <>
      <div className="home-page-icon-boxes">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="icon-box active">
                <figure className="d-flex justify-content-center">
                  <img src="images/logos/crest.jpg" id="newimage" alt="" />
                  <img src="images/logos/crest.jpg" id="newimage" alt="" />
                </figure>
                <header className="entry-header">
                  <h6 className="charism">OUR CHARISM</h6>
                </header>
                <div className="entry-content">
                  <p className=" text-black">
                    ” To live out or spread the love of the Sacred Hearts of
                    Jesus and Mary! “
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ourstatic;
