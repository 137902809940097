import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ApiUrl } from "./API/Api";
import { useLocation } from "react-router-dom";

function Header() {
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    axios
      .get(`${ApiUrl}/get/menus`)
      .then((response) => {
        setMenuData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching menu:", error);
        setLoading(false);
      });
  }, []);

  const renderMenuItems = () => {
    return menuData.map((item) => (
      <li
        key={item.id}
        className={`nav-item ${currentPath === item.url ? "active" : ""} `}
      >
        <Link className="nav-link" to={item.url}>
          {item.label}
        </Link>
      </li>
    ));
  };
  return (
    <>
      <header className="site-header">
        <div className="top-header-bar">
          <div className="container">
            <div className="row flex-wrap justify-content-center justify-content-lg-between align-items-lg-center">
              <div className="col-12 col-lg-8 d-none d-md-flex flex-wrap justify-content-center justify-content-lg-start mb-3 mb-lg-0">
                <div className="header-bar-email" style={{ fontSize: "15px" }}>
                  <i
                    className="fa fa-envelope"
                    style={{ color: "#f6c93f" }}
                  ></i>
                  <a href="mailto:sacredheartsfatherssociety@gmail.com">
                    <span
                      className="__cf_email__"
                      style={{
                        marginLeft: "0.5rem",
                        fontSize: "13px",
                        fontFamily: "Lato, Sans-serif",
                      }}
                    >
                      sacredheartsfatherssociety@gmail.com
                    </span>
                  </a>
                </div>
                <div
                  className="header-bar-text align-items-center justify-content-center"
                  style={{ fontSize: "15px", marginTop: "-13px" }}
                >
                  <i className="fa fa-phone" style={{ color: "#f6c93f" }}></i>
                  <a
                    href="tel:+919865910951"
                    style={{
                      marginLeft: "0.3rem",
                      fontSize: "13px",
                      fontFamily: "Lato, Sans-serif",
                    }}
                  >
                    +91 98659 10951
                  </a>
                </div>
              </div>
              <div
                className="col-12 col-lg-4 d-flex flex-wrap justify-content-center justify-content-lg-end align-items-center"
                style={{ marginTop: "-13px" }}
              >
                <div className="social-icons">
                  <div className="social-icon">
                    <a href="/" target="_blank" rel="noreferrer">
                      <i className="fab fa-facebook" />
                    </a>
                  </div>
                  <div className="social-icon">
                    <a href="/" target="_blank" rel="noreferrer">
                      <i className="fab fa-youtube" />
                    </a>
                  </div>
                  <div className="social-icon">
                    <a href="/" target="_blank" rel="noreferrer">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="sp-section-3">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="sp-column">
              <div className="sp-module main-banner">
                <div className="sp-module-content">
                  <div className="custom main-banner">
                    <img
                      style={{
                        display: "block",
                        width: "100%",
                      }}
                      src="/images/msscc-banner.jpg"
                      alt="Banner"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <nav className="navbar navbar-expand-lg navbar-light custom-menu">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <ul className="navbar-nav">{!loading && renderMenuItems()}</ul>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
