import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <div className="home-page-welcome">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 order-2 order-lg-1">
              <div className="welcome-content">
                <header className="entry-header">
                  <h2 className="entry-title">
                    Missionaries of the Sacred Hearts
                    <br /> of Jesus & Mary
                  </h2>
                </header>
                <div className="entry-content mt-2">
                  The Missionaries of the Sacred Hearts of Jesus and Mary, a
                  religious congregation of priests and brothers, dedicated in
                  serving the needs of the People of God and witnessing love.Our
                  founder Saint Gaetano Errico, was ordained as a secular priest
                  in Naples, Italy, in 1815. Father Errico longed to share his
                  burning love for the Hearts of Jesus and Mary and to lead
                  others to witness the boundless love their precious hearts
                  possessed for all mankind.
                </div>

                <center>
                  <Link to="/history">
                    <button className="brown-button">Read More</button>
                  </Link>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
